import Echo from 'laravel-echo'
import AuthRepository from '@/repositories/general/auth-repository'

export function initLaravelEcho () {
  window.Pusher = require('pusher-js')

  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    forceTLS: true,
    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callBack) => {
          const params = { socket_id: socketId, channel_name: channel.name }
          AuthRepository.authenticateBroadcasting(params)
            .then(response => {
              callBack(false, response.data)
            })
            .catch(error => {
              callBack(true, error)
            })
        }
      }
    }
  })
}
