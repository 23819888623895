<template>
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer" :key="dropdownKey" @blur="onDropdownClosed">
    <feather-icon ref="bellIcon" icon="BellIcon" class="cursor-pointer mt-1 sm:mr-6 mr-2" :badge="unreadNotifications.length" />
    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">
<!--      <div v-if="!isNotificationPermissionGranted" class="notification-top text-center p-5 bg-danger text-white">-->
<!--        <h6 class="text-white">Mohon ijinkan aplikasi untuk pengiriman notifikasi.</h6>-->
<!--      </div>-->
      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">{{ unreadNotifications.length }} New</h3>
        <p class="opacity-75">Unread Notifications</p>
      </div>
      <VuePerfectScrollbar ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0" :settings="settings" :key="$vs.rtl">
        <ul class="bordered-items">
          <li v-for="(notification, index) in notifications" :key="index" class="flex justify-between px-4 py-4 notification cursor-pointer" :class="{'bg-warning-transparent-10': !notification.read_at}" @click="onClickNotificationItem(notification)">
            <div class="flex items-start">
              <feather-icon :icon="notificationIcon(notification)" :svgClasses="[`text-${notificationColor(notification)}`, 'stroke-current mr-1 heads-6 w-6']"></feather-icon>
              <div class="mx-2">
                <span class="font-medium block notification-title" :class="[`text-${notificationColor(notification)}`]">{{ notification.data.title }}</span>
                <small>{{ notification.data.description }}</small>
              </div>
            </div>
            <small class="mt-1 whitespace-no-wrap">{{ elapsedTime(notification.created_at) }}</small>
          </li>
          <li v-if="notifications.length < meta.total" class="flex justify-center px-4 py-4 notification cursor-pointer" @click="loadMore">
            <span v-if="loading">Loading...</span>
            <span v-else>Load more</span>
          </li>
        </ul>
      </VuePerfectScrollbar>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import NotificationRepository from '@/repositories/general/notification-repository'
import moduleNotification from '@/store/modules/general/notification/notification.store'
import notificationMixin from '@/utilities/mixins/notification-mixin'
import _ from 'lodash'
import firebaseConfig from '@/config/firebase'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { initLaravelEcho } from '@/utilities/plugins/laravel-echo/laravel-echo'
import { getClientDeviceName } from '@/utilities/common/global-methods'

export default {
  components: {
    VuePerfectScrollbar
  },
  mixins: [notificationMixin],
  computed: {
    storeNotification () {
      return this.$store.state.general.notification
    },
    notifications () {
      return this.storeNotification.notifications
    },
    unreadNotifications () {
      return _.filter(this.notifications, item => !item.read_at)
    }
  },
  watch: {
    unreadNotifications (newVal) {
      if (newVal.length > 0) {
        document.title = `(${newVal.length}) ${this.$route.meta.title}`
      } else {
        document.title = this.$route.meta.title
      }
    }
  },
  mounted () {
    this.initFirebase()
    this.initFcm()
    this.initWebsocket()
    setTimeout(() => {
      this.getData()
    }, 300)
  },
  data () {
    return {
      loading: false,
      isNotificationPermissionGranted: false,
      dropdownKey: 0,
      meta: {
        page: 1,
        limit: 50,
        total: 0
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.60
      }
    }
  },
  methods: {
    initFirebase () {
      initializeApp(firebaseConfig)
    },

    initFcm () {
      const messaging = getMessaging()
      const options = { vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY }

      // get user device token
      getToken(messaging, options)
        .then((currentToken) => {
          if (currentToken) {
            this.isNotificationPermissionGranted = true
            this.saveToken(currentToken)
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err)
        })

      // listener on new message received (foreground)
      onMessage(messaging, (payload) => {
        //
      })
    },

    async initWebsocket () {
      await initLaravelEcho()
      this.subscribeWebsocketNotificationChannel()
    },

    subscribeWebsocketNotificationChannel () {
      const userId = this.$store.state.auth.user.id
      window.Echo.private('App.Models.User.' + userId)
        .notification((data) => {
          this.onNewNotificationReceivedByWebsocket(data)
        })
    },

    onNewNotificationReceivedByWebsocket (notification) {
      const notificationData = JSON.parse(notification.data)
      new Audio(require('@/assets/audio/notification.mp3')).play()
      this.unshiftNotification(notification)
      this.notify(notificationData.title, notificationData.description)
      if (notificationData.type === 'APPROVAL_RECEIVED') {
        this.$store.dispatch('general/navbarBadge/getDataForMenuApproval')
      }
    },

    onClickNotificationItem (notification) {
      this.handleNotification(notification)
      this.closeDropdown()
    },

    unshiftNotification (notification) {
      notification.data = JSON.parse(notification.data)
      this.$store.commit('general/notification/UNSHIFT_NOTIFICATION', notification)
    },

    pushNotification (notification) {
      notification.data = JSON.parse(notification.data)
      this.$store.commit('general/notification/PUSH_NOTIFICATION', notification)
    },

    loadMore () {
      this.meta.page = this.meta.page + 1
      this.getData()
    },

    getData () {
      this.loading = true

      const params = { page: this.meta.page, limit: this.meta.limit }
      NotificationRepository.get(params)
        .then(response => {
          this.meta.total = response.data.data.total
          _.each(response.data.data.data, item => {
            this.pushNotification(item)
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    markAsRead (notificationIds) {
      const params = { notification_ids: notificationIds }
      NotificationRepository.read(params)
        .then(response => {
          this.$store.commit('general/notification/MARK_AS_READ_NOTIFICATIONS', params.notification_ids)
        })
        .catch(error => {
          console.log(error)
        })
    },

    saveToken (token) {
      const params = { deviceName: getClientDeviceName(), token: token }
      NotificationRepository.saveToken(params)
        .then(response => {
          localStorage.setItem('deviceToken', token)
          this.$store.commit('general/notification/SET_DEVICE_TOKEN', token)
        })
        .catch(error => {
          console.log(error)
        })
    },

    closeDropdown () {
      this.dropdownKey = Math.random()
    },

    onDropdownClosed: _.debounce(function (e) {
      const unreadNotificationIds = _.map(this.unreadNotifications, item => item.id)
      if (unreadNotificationIds.length > 0) {
        this.markAsRead(unreadNotificationIds)
      }
    }, 500),

    notificationIcon (notification) {
      const level = notification.data.level
      if (level === 'INFO') {
        return 'InfoIcon'
      } else if (level === 'SUCCESS') {
        return 'CheckCircleIcon'
      } else if (level === 'WARNING') {
        return 'AlertTriangleIcon'
      } else if (level === 'DANGER') {
        return 'AlertCircleIcon'
      } else {
        return 'BellIcon'
      }
    },

    notificationColor (notification) {
      const level = notification.data.level
      if (level === 'INFO') {
        return 'primary'
      } else if (level === 'SUCCESS') {
        return 'success'
      } else if (level === 'WARNING') {
        return 'warning'
      } else if (level === 'DANGER') {
        return 'danger'
      } else {
        return 'primary'
      }
    },

    elapsedTime (startTime) {
      const x = new Date(startTime)
      const now = new Date()
      let timeDiff = now - x
      timeDiff /= 1000

      const seconds = Math.round(timeDiff)
      timeDiff = Math.floor(timeDiff / 60)

      const minutes = Math.round(timeDiff % 60)
      timeDiff = Math.floor(timeDiff / 60)

      const hours = Math.round(timeDiff % 24)
      timeDiff = Math.floor(timeDiff / 24)

      const days = Math.round(timeDiff % 365)
      timeDiff = Math.floor(timeDiff / 365)

      const years = timeDiff

      if (years > 0) {
        return years + (years > 1 ? ' Years ' : ' Year ') + 'ago'
      } else if (days > 0) {
        return days + (days > 1 ? ' Days ' : ' Day ') + 'ago'
      } else if (hours > 0) {
        return hours + (hours > 1 ? ' Hrs ' : ' Hour ') + 'ago'
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? ' Mins ' : ' Min ') + 'ago'
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? ' sec ago' : 'just now')
      }

      return 'Just Now'
    }
  },
  beforeCreate () {
    this.$store.registerModule(['general', 'notification'], moduleNotification)
  },
  beforeDestroy () {
    this.$store.commit('general/notification/RESET_STATE')
    this.$store.unregisterModule(['general', 'notification'])
  }
}

</script>
