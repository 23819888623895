const firebaseConfig = {
  apiKey: 'AIzaSyDgVJDB8zmUnj30xJB1c0uqdU5oURjtNTw',
  authDomain: 'dafis-ba4b2.firebaseapp.com',
  projectId: 'dafis-ba4b2',
  storageBucket: 'dafis-ba4b2.appspot.com',
  messagingSenderId: '180433094068',
  appId: '1:180433094068:web:3fd622aea3ceb92f9bdee1',
  measurementId: 'G-7K6LCXCLRL'
}

export default firebaseConfig
