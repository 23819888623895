import moment from 'moment'
import _ from 'lodash'

const getDefaultState = () => {
  return {
    notifications: [],
    deviceToken: null
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  mutations: {
    UNSHIFT_NOTIFICATION (state, notification) {
      state.notifications.unshift(notification)
    },
    PUSH_NOTIFICATION (state, notification) {
      state.notifications.push(notification)
    },
    SET_DEVICE_TOKEN (state, token) {
      state.deviceToken = token
    },
    MARK_AS_READ_NOTIFICATIONS (state, notificationIds) {
      _.each(notificationIds, id => {
        const item = _.find(state.notifications, notification => notification.id === id)
        item.read_at = moment().format('YYYY-MM-DD H:mm:ss')
      })
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  }
}
