import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/general/notifications'

export default {
  get (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  read (params) {
    return httpClient.patch(`${endpoint}`, params)
  },

  saveToken (params) {
    return httpClient.post(`${endpoint}/token`, params)
  }
}
